<template>
  <div class="flowFormContainer">
    <div class="flowFormContent" >
      <v-row dense>
        <v-col :cols="12" class="formInputCol px-4" :class="{ hideQuestion : question.type === 'LES Flag'}">
          <label
            class="flowInputLabel"
            :data-question_id="question.id"
            v-if="question.type !== 'Display Only'"
            :class="{ isLESFlagQuestion : question.type === 'LES Flag' }"
            >
            <span v-html="question.description"></span>
            <span v-if="question.required" class="required-asterisk">*</span>
          </label>
          <template v-if="question.type === 'select'">
            <div class="dropdownContainer">
              <DropDown
                :name="question.description"
                :options="question.options"
                :default="!question.noDefault && getDefaultValue(question)"
                @input="updateDropDownVal(question, $event)"
                :value="
                  question?.options?.find(
                    (option) => option.value === submissionData?.[question.id]
                  ) || submissionData[question.id]
                "
              />
            </div>
          </template>
          <template
            v-if="question.type === 'CheckBox'"
          >
          <RadioComp
              :input="question"
              :submissionData="submissionData && Object.keys(submissionData).length > 0 ? submissionData : formData"
              @toggle-selected="updateCheckboxValue(question, $event)"
              :multiSelect=true
              :style="{ width: '100%' }"
            />
          </template>
          <template v-else-if="question.type === 'Display Only'">
            <div class="displayOnlyText">
              <span v-html="question.description"></span>
            </div>
          </template>
          <template v-else-if="question.type === 'multiSelect'">
            <DropDownMulti
              :name="question.description"
              :options="question.options"
              placeholder="Select Fields"
              :default="getDefaultValue(question)"
              @selected-values="updateDropDownMultiVal(question, $event)"
              :value="submissionData[question.id]"
              :style="{ width: '50%' }"
            />
          </template>
          <template v-else-if="question.description === 'Subject'">
            <v-col class="formInputCol" :style="{ padding: '0px' }">
              <FillInBubble
                :isRequired="question.required"
                @input="updateInputVal(question, $event)"
                :value="submissionData[question.description]"
                :style="{ width: '50%' }"
              />
              <BulkUpdatePanel class="bulkUpdateText" />
            </v-col>
          </template>
          <template v-else-if="question.type === 'Text Box'">
              <TextBox
                :isRequired="question.required"
                @input="updateInputVal(question, $event)"
                :value="submissionData?.[question.value] || submissionData?.[question.id] ? submissionData?.[question.value] || submissionData?.[question.id] 
                  : formData?.[question.value] || formData?.[question.id] ? formData?.[question.value] || formData?.[question.id] : ''"
                :style="{ width: '50%' }"
              />
          </template>
          <template v-else-if="question.type === 'Radio Button Fields'">
            <RadioComp
              :input="question"
              :submissionData="submissionData && Object.keys(submissionData).length > 0 ? submissionData : formData"
              @toggle-selected="updateRadioValue(question, $event)"
              :multiSelect="question.multiSelect"
              :style="{ width: '100%' }"
            />
          </template>
          <template v-else-if="question.type === 'LES Flag'">
            <LESFlagComp
              :selectedFlow="selectedFlow"
              :input="question"
              :submissionData="submissionData && Object.keys(submissionData).length > 0 ? submissionData : formData"
              @toggle-selected="updateRadioValue(question, $event)"
              :multiSelect="false"
              :style="{ width: '100%', display: 'None' }"
              :LESFlag="LESFlag"
            />
          </template>
          <template v-else-if="question.type === 'Standard Text Field'">
            <TextBox
                :isRequired="question.required"
                @input="updateInputVal(question, $event)"
                :value="submissionData?.[question.value] || submissionData?.[question.id] ? submissionData?.[question.value] || submissionData?.[question.id] 
                  : formData?.[question.value] || formData?.[question.id] ? formData?.[question.value] || formData?.[question.id] : ''"
                :style="{ width: '50%' }"
              />
          </template>

          <template v-else-if="question.type === 'dateRange'">
            <DatePicker
              :allowDateRange="true"
              :input="question"
              @date-value="handleDateChange"
              :style="{ width: '50%' }"
            />
          </template>
          <template v-else-if="question.type === 'datePicker'">
            <DatePicker
              :allowDateRange="false"
              :input="question"
              @date-value="handleDateChange"
              :value="previousFormValues?.[question.value]"
              :style="{ width: '50%' }"
            /> </template
          ><template v-else-if="question.type === 'timePicker'">
            <TimePicker
              :allowDateRange="false"
              :input="question"
              @date-value="handleDateChange"
              :value="submissionData?.[question.value]"
              :existingDate="
                submissionData['dateEST'] || previousFormValues?.['dateEST']
              "
              :style="{ width: '50%' }"
            />
          </template>
          <template v-else-if="question.type === 'button'">
            <slot name="button" :sendSubmissionData="sendSubmissionData">
              <DarkButton> {{ question.description }}</DarkButton>
            </slot>
          </template>
          <template v-else-if="question.type === 'filePicker'">
            <slot name="file-picker"></slot>
          </template>
          <template v-else-if="question.type === 'Smart Link'">
            <SmartLinkFlow :url="question.url" :question="question.description" />
          </template>
          <template v-else-if="question.type === 'textarea'">
            <slot name="textarea">
              <textarea
                :readonly="
                  question.readonly !== undefined ? question.readonly : true
                "
                :value="
                  submissionData?.[question.value] ||
                  submissionData?.[question.id] ||
                  question.text
                "
                class="textareaNormal"
                @change="updateInputVal(question, $event)"
                :style="{ width: '50%' }"
              ></textarea>
            </slot>
          </template>
          <template v-else-if="question.type === 'Custom Action'">
            <CustomActionQuestion 
              :script="question.description"
              :account_number="account_number"
              :ticket_number="ticket_number"
              :queue="queue"
              @toggle-selected="updateRadioValue(question, $event)"
            />
          </template>
          <template v-else-if="question.type === 'Custom Display'">
            <CustomDisplayQuestion 
              :question="question"
              :script="question.description"
              :account_number="account_number"
              :ticket_number="ticket_number"
              :queue="queue"
            >
            </CustomDisplayQuestion>
          </template>
        </v-col>
        <!-- TODO: POTENTIAL PERFORMANCE ISSUE / INFINITE LOOP HERE (Component recalling itself recursively) -->
        <div v-for="(dependent, index) in filteredDependents" :key="index" style="width: 100%;">
          <FlowInput
            :style="{ paddingLeft: '6px' }"
            v-if="checkDependent(dependent) === true"
            :question="dependent"
            :formData="formData"
            :account_number="account_number"
            :ticket_number="ticket_number"
            :queue="queue"
            :LESFlag="LESFlag"
            @updateFlowNav="updateFlowInputNav"
            @update-downloaded-flow-segment="emitDependentFlowSegment"
            @scrollToBottom="scrollToBottomHandler"
            @submission-data="
              (submissionData) =>
                this.$emit('submission-data', {
                  ...submissionData,
                  ...this.submissionData,
                })
            "
            @remove-key="(key) => $emit('remove-key', key)"
            @updateFlowIndex="$emit('updateFlowIndex')"
            :flowIndex="flowIndex"
            :bucket="bucket"
          />
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import DropDown from "@/components/base_components/FormComponents/DropDown.vue";
import FillInBubble from "@/components/base_components/FormComponents/FillInBubble.vue";
import DarkButton from "@/components/base_components/Buttons/DarkButton.vue";
import DropDownMulti from "@/components/base_components/FormComponents/DropDownMulti.vue";
import BulkUpdatePanel from "@/components/base_components/Textbox/BulkUpdatePanel.vue";
import DatePicker from "@/components/base_components/FormComponents/DatePicker.vue";
//import ExpandableFillInBubble from "@/components/base_components/ExpandableFillInBubble.vue";
import TextBox from "@/components/CcManagement/Flows/TextBox.vue";
import RadioComp from "./RadioComp.vue";
import LESFlagComp from "./LESFlagComp.vue";
import TimePicker from "@/components/base_components/FormComponents/TimePicker.vue";
import SmartLinkFlow from "./SmartLinkFlow.vue";
import CustomActionQuestion from "./CustomActionQuestion.vue";
import CustomDisplayQuestion from "./CustomDisplayQuestion.vue";
import { useS3Store } from "@/stores/aws/s3Store";
import { useAuthStore } from "@/stores/auth/authStore";

export default {
  name: "FlowInput",
  props: {
    selectedFlow: Object,
    flowIndex: Number,
    formData: Object,
    title: String,
    account_number: String,
    ticket_number: String,
    queue: String,
    numCols: Number,
    removeIcon: Boolean,
    previousFormValues: Object,
    question: Object,
    index: Number,
    LESFlag: {
      type: Boolean,
      default: false
    },
    bucket: String,
  },
  watch: {
    windowHeight() {
      this.$nextTick(() => {
        this.dynamicHeight;
      });
    },
    previousFormValues: {
      deep: true,
      handler(newValues) {
        //console.log("previousFormValues", newValues);
        this.previousFormValuesTriggered = true;
        this.submissionData = { ...newValues };
      },
    },

    formData: {
      deep: true,
      handler(newData) {
        this.submissionData = newData;
      },
    },
  },
  computed: {
    dynamicHeight() {
      return Number(this.windowHeight) - 93;
    },
    dropdownName(question) {
      return `Select a ${question.description}`;
    },
    getButtonWidth() {
      return this.buttonOptions?.fillToParent ? "parent" : "";
    },
    isFormValid() {
      return true;
    },
    filteredDependents() {
      return this.question?.dependents?.filter((dependent) => {
        var dependentOn = dependent?.dependentOn;
        if(typeof dependent?.dependentOn == 'boolean') {
          dependentOn = dependent?.dependentOn.toString();
        }

        if(Array.isArray(this.submissionData[this.question.id])) {
          for(let selectedOption of this.submissionData[this.question.id]) {
            if(typeof selectedOption === 'object' && selectedOption !== null) {
              var selectedValue = selectedOption.value;
              if(typeof selectedOption.value == 'boolean') {
                dependentOn = selectedOption.value.toString();
              }

              if(dependentOn === selectedValue) {
                return true;
              }
            } else {
              var selected_value = selectedOption;
              if(typeof selectedOption == 'boolean') {
                selected_value = selectedOption.toString();
              }

              if(dependentOn === selected_value) {
                return true;
              }
            }
          }
          return false;
        } else {
          var formatted_value = this.submissionData[this.question.id];

          if(typeof this.submissionData[this.question.id] == 'boolean') {
            formatted_value = this.submissionData[this.question.id].toString();
          }

          return dependentOn === formatted_value;
        }
      });
    },
  },
  data() {
    return {
      submissionData: {},
      hasScrollbar: false,
      windowHeight: null,
      showDatePicker: false,
      selectedStartDate: "",
      selectedEndDate: "",
      previousFormValuesTriggered: false,
      allDependentsAnswered: false,
      nextUnAnsweredDependent: null
    };
  },
  methods: {
    checkDependent(dependent) {
      if(dependent.flowIndex > this.flowIndex) {
        return false;
      }

      var dependentOn = dependent?.dependentOn;
      if(typeof dependent?.dependentOn == 'boolean') {
        dependentOn = dependent?.dependentOn.toString();
      }

      if(Array.isArray(this.submissionData[this.question.id])) {
        for(let selectedOption of this.submissionData[this.question.id]) {

          if(typeof selectedOption === 'object' && selectedOption !== null) {
            
            var selectedValue = selectedOption.value;
            if(typeof selectedOption.value == 'boolean') {
              dependentOn = selectedOption.value.toString();
            }

            if(dependentOn === selectedValue) {
              return true;
            }
          } else {

            var selected_value = selectedOption;
            if(typeof selectedOption == 'boolean') {
              selected_value = selectedOption.toString();
            }

            if(dependentOn === selected_value) {
              return true;
            }
          }
        }
        return false;
      } else {
        var formatted_value = this.submissionData[this.question.id];

        if(typeof this.submissionData[this.question.id] == 'boolean') {
          formatted_value = this.submissionData[this.question.id].toString();
        }

        return dependentOn === formatted_value;
      }
    },
    handleDateChange(question, value) {
      if (question.value) {
        this.submissionData[question.value] = value;
        this.sendOffUpdatedSubmissionData();
      } else {
        this.submissionData[question.description] = value;
        this.sendOffUpdatedSubmissionData();
      }
    },
    toggleDatePicker() {
      this.showDatePicker = !this.showDatePicker;
    },
    updateSelectedDate(date) {
      this.selectedStartDate = date;
    },
    onSubmit() {
      if (this.isFormValid) {
        this.$emit("submission-data", this.submissionData);
      } else {
        this.$emit("submission-data", "Error");
      }
    },
    getDefaultValue(question) {
      const vowels = ["a", "e", "i", "o", "u"];

      const inputName = question.description.toLowerCase();

      return vowels.includes(inputName.charAt(0))
        ? `Select an ${inputName}`
        : `Select a ${inputName}`;
    },
    sendSubmissionData() {
      // this is a function independent from the submit form. Used for partially sending values
      // even if some required fields arent set (used in Iconfig main UI for adding a user)
      this.$emit("submission-data-independent", this.submissionData);
    },
    updateDropDownVal(question, event) {
      if (question.value) {
        this.submissionData[question.value] = event;
        this.sendOffUpdatedSubmissionData();
      } else {
        this.submissionData[question.description] = event;
        this.sendOffUpdatedSubmissionData();
      }
      let name = question.description;
      this.$emit("dropdown-data", { name, value: event });

      // check if this input control's any other dropdown data
      //   const currentInputObject = this.formData[1].inputs.find(
      //     (input) => question.description === name
      //   );

      //   if (currentInputObject.controls) {
      //     const dependent = currentInputObject.controls;
      //     this.submissionData[dependent] = null;
      //     this.$emit("dependent-dropdown-data");
      //   }
    },
    updateDropDownMultiVal(input, value) {
      this.submissionData[input.id] = value;
      this.sendOffUpdatedSubmissionData();
      this.$emit("dropdown-data", { input, value: value });
    },
    sendOffUpdatedSubmissionData() {
      this.$emit("submission-data", this.submissionData);
    },
    handleUserInput(option) {
      const currentQuestion = this.question;

      this.$emit("submission-data", this.submissionData);

      let dependents = currentQuestion?.dependents;
      for (let i = 0; i < dependents?.length; i++) {
        let dependent = dependents[i];
        var dependsOn = dependent.dependentOn;
        var optionValue = option.value;
        if(typeof dependent.dependentOn == 'boolean') {
          dependsOn = dependent.dependentOn.toString();
        }
        if(typeof option.value == 'boolean') {
          optionValue = option.value.toString();
        }
        if (Object.keys(this.submissionData).includes(String(dependent.id)) && dependsOn != optionValue) {
          var dependentKeysToRemove = Object.keys(this.submissionData).filter(x => x.startsWith(`${dependent.id}` || x == dependent.id));
          for(var key of dependentKeysToRemove) {
            this.$emit("remove-key", key);
          }
        }
      }
    },
    updateInputVal(currentQuestion, value) {
      if (typeof value === 'string') {
        this.submissionData[currentQuestion.id] = value;
        this.handleUserInput();
        this.$emit("updateFlowIndex");
      } else {
        delete this.submissionData[currentQuestion.id];
      }
    },
    handleSmartLink(currentQuestion, url) {
      if (url) {
        this.submissionData[currentQuestion.id] = url;
        this.handleUserInput();
      } else {
        delete this.submissionData[currentQuestion.id];
      }
    },
    handleClose() {
      this.$emit("close-click");
    },
    emitDependentFlowSegment(dependents, question) {
      this.$emit("update-downloaded-flow-segment", dependents, question);
    },
    async updateCheckboxValue(question, option) {
      if (Array.isArray(option) && question) {
        this.submissionData[question.id] = option;
        this.sendOffUpdatedSubmissionData();
      } else {
        if (question.id) {
          this.submissionData[question.id] = option.value;
          this.sendOffUpdatedSubmissionData();
        }
      }
      if (this.question?.dependents) {
        var optionValueArray = option.map(obj => obj.value);
        var dependents = this.question?.dependents.filter((dep) => {
          var dependsOn = dep.dependentOn;
          if(typeof dep.dependentOn == 'boolean') {
            dependsOn = dep.dependentOn.toString();
          }
          if(Array.isArray(option)) {
            return optionValueArray.indexOf(dependsOn) != -1;
          } else {
            var optionValue = option.value;
            if(typeof option.value == 'boolean') {
              optionValue = option.value.toString();
            }
            return dependsOn == optionValue;
          }
        });

        var dependentsObj = [];
        let local_allDependentsAnswered = true;
        var nextUnansweredDependent = null;

        const S3 = useS3Store();
        const authStore = useAuthStore();


        for(var i = 0; i < dependents.length; i++) {
          var dependent = dependents[i];
          if (dependent.type === "Flow") {

            if (Object.keys(dependent).indexOf("dependents") == -1) {
              
              const flow_file = await S3.getObject({
                Bucket: authStore.$state.s3_bucket,
                Key: `public/Flows-${this.bucket}/${dependent.flow_file}`
              });

              const flow_json = JSON.parse(flow_file.Body.toString());
              dependentsObj.push({questionToPass: dependent, flowJson: flow_json});
            }
          }

          if (!this.submissionData[dependent.id] && dependent.type !== "Flow" && dependent.type !== "Display Only" && dependent.type !== "Display In Notes" && dependent.type !== "Smart Link" && dependent.type !== "Custom Display") {
            local_allDependentsAnswered = false;
          }

          if (!local_allDependentsAnswered && nextUnansweredDependent == null) {
            nextUnansweredDependent = dependent;
          }
        }

        for(var item of dependentsObj) {
          this.$emit("update-downloaded-flow-segment", item.flowJson, item.questionToPass);  
        }
        
        this.allDependentsAnswered = local_allDependentsAnswered;

        if (local_allDependentsAnswered) {
          this.nextUnAnsweredDependent = null;
          this.handleUserInput(option);
        } else {
          this.$emit("scrollToBottom");
        }
      } else {
        this.handleUserInput(option);
      }
      this.$emit("updateFlowIndex");
      this.$nextTick(() => {
        this.$emit('update-flow-nav');
      });
    },
    async updateRadioValue(question, option) {

      if (Array.isArray(option) && question) {
        this.submissionData[question.id] = option;
        this.sendOffUpdatedSubmissionData();
      } else {
        if (question.id) {
          this.submissionData[question.id] = option.value;
          this.sendOffUpdatedSubmissionData();
        }
      }
      if (this.question?.dependents) {
        var dependents = this.question?.dependents.filter((dep) => {
            var dependent = dep.dependentOn;
            var optionValue = option.value;
            if(typeof dep.dependentOn == "boolean") {
              dependent = dep.dependentOn.toString();
            }
            if(typeof option.value == "boolean") {
              optionValue = option.value.toString();
            }

            return dependent == optionValue;
        });

        var dependentsObj = [];
        let local_allDependentsAnswered = true;
        var nextUnansweredDependent = null;
        const S3 = useS3Store();
        const authStore = useAuthStore();


        for(var i = 0; i < dependents.length; i++) {
          var dependent = dependents[i];
          if (dependent.type === "Flow") {

            if (Object.keys(dependent).indexOf("dependents") == -1) {
              
              const flow_file = await S3.getObject({
                Bucket: authStore.$state.s3_bucket,
                Key: `public/Flows-${this.bucket}/${dependent.flow_file}`
              });

              const flow_json = JSON.parse(flow_file.Body.toString());
              dependentsObj.push({questionToPass: dependent, flowJson: flow_json});
            }
          }

          if (!this.submissionData[dependent.id] && dependent.type !== "Flow" && dependent.type !== "Display Only" && dependent.type !== "Display In Notes" && dependent.type !== "Smart Link" && dependent.type !== "Custom Display") {
            local_allDependentsAnswered = false;
          }

          if (!local_allDependentsAnswered && nextUnansweredDependent == null) {
            nextUnansweredDependent = dependent;
          }
        }

        for(var item of dependentsObj) {
          this.$emit("update-downloaded-flow-segment", item.flowJson, item.questionToPass);  
        }
        
        this.allDependentsAnswered = local_allDependentsAnswered;

        if (local_allDependentsAnswered) {
          this.nextUnAnsweredDependent = null;
          this.handleUserInput(option);
        } else {
          this.$emit("scrollToBottom");
        }
      } else {
        this.handleUserInput(option);
      }
      
      this.$emit("updateFlowIndex");
      this.$nextTick(() => {
        this.$emit('update-flow-nav');
      });
    },
    updateFlowInputNav() {
      
      this.$emit("updateFlowIndex");
      this.$nextTick(() => {
        this.$emit('update-flow-nav');
      });
    },
    scrollToBottomHandler() {
      this.$nextTick(() => {
        this.$emit("scrollToBottom");
      });
    },
    updateOptionsVal(type, value) {
      this.submissionData[type] = value;
      this.sendOffUpdatedSubmissionData();
    },
    clearForm() {
      this.submissionData = {};
    },
    checkScrollbar() {
      //nothing
    },
    handleResize() {
      this.windowHeight = window.innerHeight;
      this.checkScrollbar();
    },
  },
  mounted() {
    if(typeof this.previousFormValues != 'undefined') {
      this.submissionData = { ...this.previousFormValues };
    } else {
      if(this.formData && Object.keys(this.formData).length > 0) {
        this.submissionData = { ...this.formData };
      }
    }
    window.addEventListener("resize", this.handleResize);
    this.windowHeight = window.innerHeight;
    this.checkScrollbar();
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },

  updated() {
    this.checkScrollbar();
  },
  components: {
    FillInBubble,
    DropDown,
    DarkButton,
    DropDownMulti,
    BulkUpdatePanel,
    DatePicker,
    RadioComp,
    LESFlagComp,
    TimePicker,
    SmartLinkFlow,
    TextBox,
    CustomActionQuestion,
    CustomDisplayQuestion
  },
  emits: [
    "submission-data",
    "dropdown-data",
    "input-data",
    "update-flow-nav",
    "dependent-dropdown-data",
    "submissionDataUpdated",
    "currentIndex",
    "questionIndex",
    "remove-key",
    "close-click",
    "update-downloaded-flow-segment",
    "submission-data-independent",
    "scrollToBottom",
    "updateFlowIndex"
  ],
};
</script>

<style scoped>
.formButtonDivider {
  margin: 0px 0px 16px 0px;
  width: 100%;
}
.checkboxWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 10px;
  padding-left: 16px;
}
.formInputCheckbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.checkboxInRow {
  padding-top: 27px;
  padding-left: 3px;
}
.formButtonContainer {
  width: 100%;
  padding-bottom: 8px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  z-index: 5;
}
.formButtonPositionBottom {
  position: absolute;
  padding-bottom: 16px;
}
.formButtonCentered {
  align-items: center;
  text-align: center;
}
.formDarkButton {
  padding: 0px 15px 0px 15px;
}
.formInputCol {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.flowFormContainer {
  background-color: #ffffff;
  overflow: hidden;
}
.flowFormContent {
  padding: 11px 0px 11px 0px;
  height: 100%;
}
.formInputs {
  padding: 11px 5px 11px 5px;
}
.formInputsWithScroll {
  height: calc(100% - 70px);
  padding-bottom: 70px;
}
.formSectionTitle {
  padding-left: 16px;
  font-size: 11pt;
  font-weight: bold;
  color: #3e87d3;
}
.formSectionDivider {
  margin: 11px 11px 25px 11px;
}
.flowInputLabel {
  color: #271d7a;
  padding-bottom: 5px;
  font-weight: 600;
  padding-bottom: 5px;
  font-size: 10pt;
}
.formHeader {
  padding-top: 5px;
  padding-left: 16px;
  padding-bottom: 10px;
  font-size: 14pt;
  font-weight: bold;
  color: #271d7a;
}
.formRemoveIcon {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  height: 35px;
  padding-top: 20px;
  padding-right: 20px;
}

.textareaNormal {
  outline: #2e3e91 solid 0.25pt;
  border-radius: 2px;
  padding-left: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 9pt;
  color: #271d7a;
  background-color: #ffffff;
  height: 150px;
}

.bulkUpdateText {
  margin-top: 30px;
}
.inLineWithInputs {
  padding-left: 35px;
}
.displayOnlyText {
  color: #271d7a;
  font-weight: 400;
}

.hideQuestion {
  display: none;
}
</style>
