import awsconfig from '@/aws-exports';
import AWS from "aws-sdk";
import { useAuthStore } from '../auth/authStore';
import { defineStore } from 'pinia'


// Composition API Store Setup

export const useS3Store = defineStore('S3', () => {
    const authStore = useAuthStore();
    const secret = authStore.getUserManagementSecret; //TODO: Secret needs to be changed to a more narrow and specific secret.
    
    AWS.config.update({
        region: awsconfig.aws_project_region,
        accessKeyId: secret.ACCESS_KEY_ID,
        secretAccessKey: secret.SECRET_ACCESS_KEY
    });

    const S3 = new AWS.S3();


    async function getObject(params, cached_response = false) {
        if (!cached_response) {
            params['ResponseCacheControl'] = "no-cache";
        }

        const result = await S3.getObject(params).promise();
        return result;
    }

    async function putObject(params) {
        const result = await S3.upload(params).promise();
        return result;
    }

    async function copyObject(params) {
        const result = await S3.copyObject(params).promise();
        return result;
    }


    return { getObject, putObject, copyObject }
});